<template>
	<ViewLayout>
		<template #header-title>
			Cookie Policy Tables
		</template>
		<template #header-caption>
			Add, edit and manage cookie policy tables
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot name="content" />
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
