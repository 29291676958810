<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookiePolicyTablesSearchQuery"
			search-query-label="Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persist-search-query="changeCookiePolicyTablesSearchQuery"
			@persist-selected-brand-id="changeAdminPortalBrandFilter"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="openCreateModal()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>

		<SectionCard>
			<template #title>
				Cookie Policy Tables
			</template>
			<template #body>
				<DataTable
					:items="filteredPolicyTables"
					:headers="tableHeaders"
					sort-by="policyTableId"
					sort-desc
					@click:row="openEditModal"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Policy Table"
							@click="openEditModal(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Policy Table"
							@click="openEditModal(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && !item.countOfAssociatedBanners && !item.countOfAssociatedCookieScripts"
							tooltip-text="Delete Policy Table"
							@click.stop.prevent="openDeletePolicyTableModal(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<!-- Delete Confirmation Overlay -->
		<delete-confirmation-modal
			:show="deleteOverlay"
			:title="'Delete ' + selectedPolicyTableName"
			:type="'Cookie Policy Table'"
			@cancelDelete="deleteOverlay = false"
			@confirmDelete="deletePolicyTable"
		/>
	</div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import DeleteConfirmationModal from '../../../shared/delete-confirmation-modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { changeCookiePolicyTablesSearchQuery, cookiePolicyTablesSearchQuery } from '../../../../../../shared/state/cookie-policy-tables.js'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { COOKIE_CONSENT_POLICY } from '../../../../router/route-names.js'
import { CAN_CREATE_UPDATE_POLICY_TABLES, CAN_DELETE_POLICY_TABLES } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'

export default {
	components: {
		'delete-confirmation-modal': DeleteConfirmationModal,
		PrimaryActionButton,
		DataTable,
		SectionCard,
		FilterBar,
		IconButton
	},
	setup () {
		return {
			showSnackbar,
			format,
			DATE_FORMAT,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeCookiePolicyTablesSearchQuery,
			cookiePolicyTablesSearchQuery
		}
	},
	data () {
		return {
			selectedPolicyTableName: '',
			deleteOverlay: false,
			selectedPolicyTableId: Number
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('cookiePolicyTables', ['cookiePolicyTables']),
		filteredPolicyTables () {
			return this.cookiePolicyTables.filter(({ policyTableName, brandId }) => {
				let check = true
				if (cookiePolicyTablesSearchQuery.value) check = policyTableName.toLowerCase().includes(cookiePolicyTablesSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_POLICY_TABLES)
		},
		tableHeaders () {
			return [
				{ value: 'policyTableId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '30%' },
				{ value: 'policyTableName', text: 'Name', width: '35%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		}
	},
	async mounted () {
		this.getCookiePolicyTables()
	},
	methods: {
		...mapActions('cookiePolicyTables', [
			'getCookiePolicyTables',
			'setCurrentPolicyTableId',
			'deleteCookiePolicyTable',
			'removeCurrentPolicyTableId'
		]),
		openCreateModal () {
			this.removeCurrentPolicyTableId()
			this.$router.push({ name: COOKIE_CONSENT_POLICY })
		},
		openEditModal (policyTable) {
			this.setCurrentPolicyTableId(policyTable.policyTableId)
			this.$router.push({
				name: COOKIE_CONSENT_POLICY,
				params: { policyTableId: policyTable.policyTableId }
			})
		},
		openDeletePolicyTableModal (policyTable) {
			this.deleteOverlay = true
			this.selectedPolicyTableName = policyTable.policyTableName
			this.selectedPolicyTableId = policyTable.policyTableId
			this.selectedCategoryBrandId = policyTable.brandId
		},
		deletePolicyTable () {
			this.deleteCookiePolicyTable(this.selectedPolicyTableId)
			this.deleteOverlay = false
			this.showSnackbar('Cookie Policy Table - ' + this.selectedPolicyTableName + ' deleted successfully')
		}
	}
}
</script>

<style lang="scss">
a {
  text-decoration: none;
}
.cassie-disabled-button {
  color: var(--global-cassie-disabled-button-color);
  background-color: var(--global-cassie-disabled-button-background-color);
  border: var(--global-cassie-disabled-button-border);
  -webkit-box-shadow: revert;
  box-shadow: revert;
}
</style>
