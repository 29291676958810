import { configApi } from '../../http-client.js'

export const getPolicyTableHeaderTranslations = () => {
	return configApi.get('/api/CookiePolicy/CookiePolicyTableTranslations').then(data => ({
		policyTableHeaderTranslations: data.data.map(policyTableHeaderTranslation => ({
			...policyTableHeaderTranslation
		}))
	}))
}

export const putPolicyTableHeaderTranslation = policyTableHeaderTranslation => {
	return configApi.put('/api/CookiePolicy/CookiePolicyTableTranslations', policyTableHeaderTranslation)
}
