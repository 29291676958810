<template>
	<PolicyTablesLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<CookiePolicyTablesTab />
				</template>
				<template #1>
					<PolicyTableHeaderTextTranslationsTab />
				</template>
			</TabItems>
		</template>
	</PolicyTablesLayout>
</template>

<script>
import CookiePolicyTablesTab from './policy-tables-tab.vue'
import PolicyTablesLayout from './policy-tables-layout.vue'
import PolicyTableHeaderTextTranslationsTab from './policy-table-header-text-translations-tab.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'

export default {
	components: { PolicyTablesLayout, CookiePolicyTablesTab, PolicyTableHeaderTextTranslationsTab, Tabs, TabItems },
	data () {
		return {
			tabs: [{ title: 'Cookie Policy Tables', slot: '0' }, { title: 'Cookie Policy Table Translations', slot: '1' }],
			tab: '0'
		}
	}
}
</script>
