<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal
			width="650px"
		>
			<template #modal-title>
				Edit Cookie Policy Table Translation - {{ headerText.languageName }}
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="headerText.cookieTranslation"
								:disabled="!userCanCreateUpdate"
								label="Cookie Script"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="headerText.cookieNameTranslation"
								:disabled="!userCanCreateUpdate"
								label="Cookie Names"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="headerText.descriptionTranslation"
								:disabled="!userCanCreateUpdate"
								label="Cookie Script Description"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="headerText.moreInformationTranslation"
								:disabled="!userCanCreateUpdate"
								label="More Information"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="headerText.cookieExpiryTranslation"
								:disabled="!userCanCreateUpdate"
								label="Cookie Expiry"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="handleSubmit(submit)"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { putPolicyTableHeaderTranslation } from '../../../../../../shared/utils/api/cookies/cookie-policy-table-header-translations.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
export default {
	components: {
		ValidationForm,
		Modal,
		IconButton,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		languageHeadersToEdit: Object,
		userCanCreateUpdate: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			headerText: JSON.parse(JSON.stringify(this.languageHeadersToEdit))
		}
	},
	methods: {
		async submit () {
			delete this.headerText.languageName
			await putPolicyTableHeaderTranslation(this.headerText)
			showSnackbar('Cookie Policy Table Header Text Updated')
			this.$emit('submit')
			this.$emit('close')
		}
	}
}
</script>
